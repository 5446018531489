import React, {useEffect,useState} from 'react';
import { Grid, Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FlashLayout from '../components/FlashLayout';
import IconTitle from '../components/IconTitle';
import getCategoryColor from '../utils/getCategoryColor';
import getCategoryFlash from '../utils/getCategoryFlash';
import options from '../utils/getRichTextOptions';
import Index from '../assets/icons/icon-Index.svg';
import Airtable from '../components/Airtable';
import { Table as TableProps } from '../models/Table';
import StickyBox from 'react-sticky-box';
import PDFPreview from '../components/PdfPreview';
import LightboxCarousel from '../components/LightboxCarousel';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import parseDate from '../utils/parseDate';

const Flash = ({location}): JSX.Element => {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const queryParams = new URLSearchParams(location.search);
  const entry = queryParams.get('entry');
 
  const [post, setPost] = useState(Object)


  
  const api_entry = "https://cdn.contentful.com/spaces/"+ process.env.GATSBY_CONTENTFUL_SPACE_ID +"/environments/"+process.env.GATSBY_CONTENTFUL_ENVIRONMENT+"/entries?access_token="+process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN+"&sys.id=" + entry
  useEffect(() => {

    // console.log(api_entry)

    const data = {
      id:"",
      title: "",
      description:"",
      category: "",
      category_color: "",
      content: "",
      categorySlug: "",
      contentSlug: 'flash',
      date:"",
      author:"",
      authorImg:"",
      authorObject: {},
      text: null || {},
      images: null || [] as any,
      tags: null || [] as any,
      pdf: "",
      tables: null || [] as any,
      color: "", 
    }

    fetch(api_entry)
    .then(response => response.json())
    .then((jsonData) => {
      // console.log('Json api')
      // console.log(jsonData)

      const item =  jsonData.items[0]
      const entrys =  jsonData.includes.Entry
      const assets = jsonData.includes.Asset

      // console.log('Item')
      // console.log(item)

      // console.log('Assets')
      // console.log(assets)

      // console.log('Entryes')
      // console.log(entrys)

      data.id = item.sys.id
      data.title = item.fields.titulo ? item.fields.titulo : 'Título de Pulso Técnico'
      data.description = item.fields.resumen ? item.fields.resumen : 'Resumen de publicación'
      data.category_color = getCategoryFlash(item.sys.contentType.sys.id).category_color
      data.category = getCategoryFlash(item.sys.contentType.sys.id).category
      data.content = getCategoryFlash(item.sys.contentType.sys.id).content
      data.date = item.sys.createdAt ? parseDate(item.sys.createdAt) : 'Fecha de publicación'

      const item_autor = entrys.find( (autor:any) => autor.sys.id ==  item.fields.autor.sys.id )
      const asset_autor = assets.find( (fotoAutor:any) => fotoAutor.sys.id == item_autor.fields.foto.sys.id )

      // console.log('Item autor')
      // console.log(item_autor)

      data.author = item_autor.fields?.nombre ? item_autor.fields?.nombre : 'Autor'
      data.authorImg = asset_autor.fields.file.url
      ? asset_autor.fields.file.url
      : 'https://images.ctfassets.net/rmz81tjgnatt/5lUaPcdzsT5ipE63OWm2Rj/e98928355712ae8e05138e89e39569d2/Group__1_.png'
      data.authorObject = {
        id : item_autor.sys.id,
        nombre: item_autor.fields.nombre,
        twitter: item_autor.fields?.twitter,
        bio: item_autor.fields?.bio,
        avatar: data.authorImg,
        puesto: item_autor.fields?.puesto,        
      }
      data.text = item.fields.texto
      
      if(item.fields.graficas){
        for (let index = 0; index < item.fields.graficas.length; index++) {
          let find_grafica = assets.find( (grafica:any) => grafica.sys.id == item.fields.graficas[index].sys.id )
          data.images.push(find_grafica.fields)
        }
      }else{
        data.images = null;
      }
      
      if(item.fields.tags){
        for (let index = 0; index < item.fields.tags.length; index++) {
          let find_tag = entrys.find( (tag:any) => tag.sys.id == item.fields.tags[index].sys.id )
          data.tags.push({
            nombre: find_tag.fields.nombre,
            slug: find_tag.fields.slug
          })
        }
      }else{
        data.tags = null;
      }

      data.pdf = item.fields.pdf && assets.find( (pdf:any) => pdf.sys.id == item.fields.pdf.sys.id )?.fields.file.url

      if(item.fields.tablas ){
        for (let index = 0; index < item.fields.tablas.length; index++) {
          let find_table = entrys.find( (tabla:any) => tabla.sys.id == item.fields.tablas[index].sys.id )
          data.tables.push(find_table.fields)
        }
      }else{
        data.tables = null;
      }
      
      data.color = getCategoryColor(data.category_color)

      // console.log("Datos flash")
      // console.log(data)
      
      setPost(data)
      
    })

  }, [])


  return (
    <FlashLayout post={post} color={post.color}>
      <>
        <>
          <h2>{post.description}</h2>
        </>
        <Grid container>
          <Grid
            // style={{ paddingRight: '10px', paddingLeft: '10px' }}
            item
            xs={12}
            sm={12}
            md={post?.tables ? 4 : 3}
          >
            {' '}
            <StickyBox offsetTop={100} style={{ width: '100%' }}>
              <Container
                style={
                  mobile
                    ? { paddingLeft: 0, paddingRight: 0 }
                    : { paddingLeft: 0, paddingRight: 20 }
                }
              >
                {post?.tables &&
                  post?.tables.map((tabla: TableProps) => {
                    if (tabla.tipo == 'tarjeta') {
                      //tarjeta
                      return (
                        <IconTitle icon={Index} title={tabla.titulo}>
                          <Airtable
                            src={tabla.tabla}
                            description={tabla.descripcion}
                            card={true}
                          />
                        </IconTitle>
                      );
                    }
                  })}
              </Container>
            </StickyBox>{' '}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ background: 'white', marginTop: 20 }}
          >
            {post.text && documentToReactComponents(post.text, options)}

            {post.images && <LightboxCarousel images={post.images} />}
            {post.pdf && <PDFPreview pdf={post.pdf} />}
            {post.tables &&
              post.tables.map((tabla: TableProps) => {
                if (tabla.tipo == '50%') {
                  return (
                    <>
                      <IconTitle icon={Index} title={tabla.titulo}>
                        <Airtable
                          src={tabla.tabla}
                          description={tabla.descripcion}
                          card={false}
                        />
                      </IconTitle>
                    </>
                  );
                }
              })}
          </Grid>
        </Grid>
        <>
          {post.tables &&
            post.tables.map((tabla: TableProps) => {
              if (tabla.tipo == '100%') {
                return (
                  <>
                    <Grid item xs={12} sm={12} md={6}></Grid>
                    <IconTitle icon={Index} title={tabla.titulo}>
                      <Airtable
                        src={tabla.tabla}
                        description={tabla.descripcion}
                        card={false}
                      />
                    </IconTitle>
                  </>
                );
              }
            })}
        </>
      </>
    </FlashLayout>
  );
};
export default Flash;