const getCategoryFlash = (content: string): Object => {
    if (content === "flashInternacional") {
      return {
          "category" : "internacional",
          "category_color" : "internacional",
          "content" : "Flash Internacional",
      }
    }

    if (content === "flashTecnico") {
      return {
          "category" : "técnico",
          "category_color" : "tecnico",
          "content" : "Flash Técnico",
      }
    }

    if (content === "flashEconomico") {
      return {
          "category" : "económico",
          "category_color" : "economico",
          "content" : "Flash Económico",
      }
    }

    if (content === "flashEmisora") {
      return {
          "category" : "renta variable",
          "category_color" : "rentaVariable",
          "content" : "Flash Emisora",
      }
    }

    if (content === "flashRentaFija") {
      return {
          "category" : "renta fija",
          "category_color" : "rentaFija",
          "content" : "Flash Renta Fija",
      }
    }

    return '';
  };
  
export default getCategoryFlash;
  